import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';
import { combineReducers, PayloadAction } from '@reduxjs/toolkit';
import createMigrate from 'redux-persist/es/createMigrate';

import { cookiesMigrations } from 'redux/cookies/migration';
import { apiSlice } from 'redux/rtk/api/apiSlice';
import { cmsSlice } from 'redux/rtk/cms/cmsSlice';
import { keycloakSlice } from 'redux/rtk/keycloak/keycloakSlice';
import { addressSlice } from 'redux/rtk/address/addressSlice';
import { KeycloakState } from 'redux/keycloak';

import { reducer as demandeRf } from './demandeRf';
import { reducer as maintenance } from './maintenance';
import { DemandeRfState } from './demandeRf/utils';
import { reducer as cookies } from './cookies';
import { reducer as keycloak } from './keycloak';
import { reducer as locale, LocaleState } from './locale';
import { reducer as pages } from './pages';
import { reducer as asile } from './asile';
import { AsileState } from './asile/utils';
import { PagesState } from './pages/pageState.model';
import { reducer as user, UserState } from './user';
import { FormsState, reducer as forms } from './forms';
import { CookiesState } from './cookies';
import { MaintenanceState } from './maintenance';

const cookiesPersistConfig = {
  key: 'cookies',
  storage: storage,
  blacklist: ['hasUserRequestedCookiesPersonalization', 'shouldReloadPage'],
  migrate: createMigrate(cookiesMigrations, { debug: false }),
  version: 0,
};

const KeycloakPersistConfig = {
  key: 'keycloak',
  storage: storageSession,
  version: 0,
};

export type RootState = Readonly<{
  pages: PagesState;
  user: UserState;
  keycloak: KeycloakState;
  asile: AsileState;
  rfDemande: DemandeRfState;
  maintenance: MaintenanceState;
  locale: LocaleState;
  cookies: CookiesState;
  forms: FormsState;
  api: any;
  cms: any;
  kc: any;
  address: any;
}>;

export default combineReducers<RootState>({
  pages,
  user,
  keycloak: persistReducer<any, PayloadAction>(KeycloakPersistConfig, keycloak),
  asile,
  rfDemande: demandeRf,
  maintenance: maintenance,
  locale,
  cookies: persistReducer<any, PayloadAction>(cookiesPersistConfig, cookies),
  forms: forms,
  [apiSlice.reducerPath]: apiSlice.reducer,
  [cmsSlice.reducerPath]: cmsSlice.reducer,
  [keycloakSlice.reducerPath]: keycloakSlice.reducer,
  [addressSlice.reducerPath]: addressSlice.reducer,
});

/* eslint-disable complexity */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import CallOut from '@codegouvfr/react-dsfr/CallOut';

import CommonRowContainer from 'components/Molecules/Forms/CommonRowContainer/CommonRowContainer';
import FormModalLayout from 'components/Molecules/DemandeRf/FormModalLayout/FormModalLayout';
import RadioButtonContainer from 'components/Molecules/Forms/RadioButtonContainer/RadioButtonContainer';
import { RESIDENCE_LIEU } from 'constants/regroupementFamiliale';
import { CHOIX } from 'constants/global';
import { handleArrayHydratation, handleFieldHydratation } from 'helpers/api/FormHydratation';
import { Enfant as EnfantType } from 'models/types/api/rf';
import { EnfantDemandeForm } from 'models/types/app/demandeRf';
import { getRfCmsContents } from 'pages/DemandeRF/Helpers/Cms/getRfCmsContents';
import { flatArray } from 'pages/DemandeRF/Helpers/common';
import { useNewEnfantMutation, useUpdEnfantMutation } from 'redux/rtk/api/apiRf';
import { updEnfantStore } from 'redux/demandeRf';
import { getListDocumentsByEnfant } from 'redux/demandeRf/selectors';
import { createError, createSuccess, submitForm } from 'redux/forms';
import { getEnfantFormToSubmit } from 'redux/forms/selectors';
import { useGetNationalitesNonEligiblesQuery } from 'redux/rtk/api/apiCommon';

import EnfantUseForm from './components/EnfantUseForm';
import { enfantAppToApiFormatter } from './helpers/enfantAppToApiFormatter';
import { getUploadFilesParameters } from './helpers/enfantUploadFiles';
import { enfantApiToAppFormatter } from './helpers/enfantApiToAppFormatter';
import { getEnfantValidationSchema } from './helpers/enfantValidationSchema';
import enfantMapping from './helpers/enfantMapping';
import EnfantInfoNoms from './components/EnfantInfoNoms';
import EnfantInfoNaissance from './components/EnfantInfoNaissance';
import EnfantLienAutreParent from './components/EnfantLienAutreParent';
import EnfantFiliation from './components/EnfantFiliation';
import EnfantActeNaissance from './components/EnfantActeNaissance';

type EnfantProps = {
  lang: string;
  demandeId: string;
  enfantModal: any;
  enfantInit: EnfantType;
};

const Enfant: React.FC<EnfantProps> = ({ lang, demandeId, enfantInit, enfantModal }) => {
  const [enfant, setEnfant] = useState<EnfantType>(enfantInit);
  const [forceModalOpen, setForceModalOpen] = useState<boolean>(false);
  const { bloc2Cms, globalCms, errorCms, utilitaireCms } = getRfCmsContents(lang);
  const dispatch = useDispatch();
  const [newEnfant, { data: dataCreated, isSuccess: isSuccessCreated, isError: isErrorCreated }] =
    useNewEnfantMutation();
  const [updEnfant, { data: dataUpdated, isSuccess: isSuccessUpdated, isError: isErrorUpdated }] =
    useUpdEnfantMutation();
  const { data: nationalitesNonEligiblesData } = useGetNationalitesNonEligiblesQuery();
  const [enfantForm, setEnfantForm] = useState<EnfantDemandeForm | null>(null);

  const makeListDocumentsByEnfant = useMemo(() => getListDocumentsByEnfant(enfant.id), [enfant.id]);
  const documents = useSelector(makeListDocumentsByEnfant);

  const { handleSubmit, getValues, setValue, trigger, watch, formState, control } = EnfantUseForm(
    errorCms,
    bloc2Cms,
    utilitaireCms,
    flatArray(nationalitesNonEligiblesData),
  );

  const rFDemande = useWatch({
    control,
    name: 'rf_demande',
  });
  const enfantEtranger = useWatch({
    control,
    name: 'enfant_etranger',
  });
  const errors = formState.errors;
  const isValid = formState.isValid;

  const uploadFilesParameter = getUploadFilesParameters(
    demandeId ? demandeId : '',
    enfant?.id ? enfant.id : undefined,
    {
      control,
      setValue,
      getValues,
      trigger,
      errors,
      watch,
    },
    utilitaireCms,
  );

  const filesMapping = enfantMapping
    .getGeneralMapping()
    .getFilesFields(bloc2Cms, uploadFilesParameter, errorCms);

  const formFieldsMapping = enfantMapping.getGeneralMapping().getFormFields(
    { bloc2Cms, globalCms },
    {
      setValue,
      getValues,
      control,
      trigger,
    },
    lang,
  );

  const fieldArrayMapping = {
    acte_naissance_enfant_files: useFieldArray({
      control,
      name: 'acte_naissance_enfant_files',
    }),
    acte_naissance_enfant_traduit_files: useFieldArray({
      control,
      name: 'acte_naissance_enfant_traduit_files',
    }),
    jugement_adoption_files: useFieldArray({
      control,
      name: 'jugement_adoption_files',
    }),
    jugement_adoption_traduit_files: useFieldArray({
      control,
      name: 'jugement_adoption_traduit_files',
    }),
    kafala_algerien_files: useFieldArray({
      control,
      name: 'kafala_algerien_files',
    }),
    kafala_algerien_traduit_files: useFieldArray({
      control,
      name: 'kafala_algerien_traduit_files',
    }),
    livret_famille_files: useFieldArray({
      control,
      name: 'livret_famille_files',
    }),
    jugement_droit_garde_enfants_files: useFieldArray({
      control,
      name: 'jugement_droit_garde_enfants_files',
    }),
    jugement_droit_garde_enfants_traduit_files: useFieldArray({
      control,
      name: 'jugement_droit_garde_enfants_traduit_files',
    }),
    autre_parent_autorisation_venue_france_files: useFieldArray({
      control,
      name: 'autre_parent_autorisation_venue_france_files',
    }),
    autre_parent_autorisation_venue_france_traduit_files: useFieldArray({
      control,
      name: 'autre_parent_autorisation_venue_france_traduit_files',
    }),
    acte_deces_files: useFieldArray({
      control,
      name: 'acte_deces_files',
    }),
    acte_deces_traduit_files: useFieldArray({
      control,
      name: 'acte_deces_traduit_files',
    }),
    declaration_disparition_autre_parent_files: useFieldArray({
      control,
      name: 'declaration_disparition_autre_parent_files',
    }),
    declaration_disparition_autre_parent_traduit_files: useFieldArray({
      control,
      name: 'declaration_disparition_autre_parent_traduit_files',
    }),
  };

  const onSubmit = async (data: any, forceModal?: boolean) => {
    const enfantId = enfant?.uuid ? null : enfant?.id ? enfant?.id : null;
    const enfantApi = enfantAppToApiFormatter(data, enfantId, documents, isValid);
    if (forceModal !== undefined) {
      setForceModalOpen(forceModal);
    } else {
      setForceModalOpen(false);
    }
    if (enfantId) {
      await updEnfant({
        demandeId: demandeId,
        body: enfantApi,
      });
    } else {
      await newEnfant({
        demandeId: demandeId,
        body: enfantApi,
      });
    }
  };

  useEffect(() => {
    if (isSuccessCreated && dataCreated) {
      dispatch(updEnfantStore({ ...dataCreated, ...{ uuid: enfant?.uuid } }));
      setEnfant(dataCreated);
      if (!forceModalOpen) {
        enfantModal.close();
      }
      dispatch(createSuccess(bloc2Cms.enfant_ajoute));
    }
  }, [isSuccessCreated, dataCreated]);

  useEffect(() => {
    if (isSuccessUpdated && dataUpdated) {
      dispatch(updEnfantStore(dataUpdated));
      setEnfant(dataUpdated);
      if (!forceModalOpen) {
        enfantModal.close();
      }
      dispatch(createSuccess(bloc2Cms.enfant_modifie));
    }
  }, [isSuccessUpdated, dataUpdated, enfant]);

  useEffect(() => {
    if (isErrorCreated || isErrorUpdated) {
      dispatch(
        createError(isErrorCreated ? errorCms.erreur_enregistrement : errorCms.erreur_modification),
      );
    }
  }, [isErrorCreated, isErrorUpdated]);

  useEffect(() => {
    if (enfant && documents) {
      setEnfantForm(enfantApiToAppFormatter(enfant, documents));
    }
  }, [enfant]);

  const setValuesFromDemand = (formDemand: any) => {
    for (const key in formDemand) {
      if (Array.isArray(formDemand[key])) {
        if (formDemand[key].length > 0) {
          handleArrayHydratation(key, formDemand, fieldArrayMapping);
        }
      } else if (formDemand[key] !== null && formDemand[key] !== undefined) {
        handleFieldHydratation(
          key,
          formDemand,
          getEnfantValidationSchema(
            errorCms,
            bloc2Cms,
            utilitaireCms,
            flatArray(nationalitesNonEligiblesData),
          ),
          setValue,
        );
      }
    }
  };

  useEffect(() => {
    if (enfantForm) {
      setValuesFromDemand(enfantForm);
    }
  }, [enfantForm]);

  const enfantFormToSubmit = useSelector(getEnfantFormToSubmit);
  useEffect(() => {
    if (enfantFormToSubmit) {
      onSubmitNoValidationAfterUpload();
      dispatch(submitForm('enfant'));
    }
  }, [enfantFormToSubmit]);

  const onSubmitNoValidationAfterUpload = () => {
    const values = control._formValues;
    delete values.validation;
    onSubmit(values, true);
  };

  return (
    <FormModalLayout
      formIdentifier="enfant-form"
      requiredInfos={true}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      lang={lang}
      control={control}
      isValid={isValid}
    >
      <CommonRowContainer
        children={[
          <RadioButtonContainer {...formFieldsMapping.rf_demande} large orientation="horizontal" />,
        ]}
      />
      <CommonRowContainer
        legend={bloc2Cms.enfant_vit}
        children={[
          <RadioButtonContainer
            {...formFieldsMapping.enfant_etranger}
            large
            orientation="horizontal"
          />,
        ]}
      />
      <div className="container fr-mt-6v fr-mt-lg-8v fr-mt-md-8v">
        <hr />
      </div>
      {rFDemande === CHOIX.OUI && (
        <>
          {enfantEtranger === RESIDENCE_LIEU.ETRANGER && (
            <>
              <EnfantInfoNoms lang={lang} formFieldsMapping={formFieldsMapping} />
              <EnfantInfoNaissance
                lang={lang}
                fieldArrayMapping={fieldArrayMapping}
                filesMapping={filesMapping}
                formFieldsMapping={formFieldsMapping}
                isNew={enfant?.uuid ? 'oui' : 'non'}
                control={control}
                formState={formState}
                nationalitesNonEligibles={flatArray(nationalitesNonEligiblesData)}
              />
              {formState.errors.date_naissance_enfant?.type !== 'mustBeMinor' &&
                formState.errors.nationalite_enfant?.type !== 'notOneOf' && (
                  <>
                    <EnfantFiliation
                      lang={lang}
                      fieldArrayMapping={fieldArrayMapping}
                      filesMapping={filesMapping}
                      formFieldsMapping={formFieldsMapping}
                      isNew={enfant?.uuid ? 'oui' : 'non'}
                      control={control}
                    />
                    <EnfantLienAutreParent
                      lang={lang}
                      fieldArrayMapping={fieldArrayMapping}
                      filesMapping={filesMapping}
                      formFieldsMapping={formFieldsMapping}
                      isNew={enfant?.uuid ? 'oui' : 'non'}
                      control={control}
                    />
                  </>
                )}
            </>
          )}
          {enfantEtranger === RESIDENCE_LIEU.FRANCE && (
            <CallOut
              iconId="fr-icon-info-line"
              title={bloc2Cms.bulle_aide_enfant_vit_france.titre}
              className="callout-alert fr-mt-2w"
            >
              {bloc2Cms.bulle_aide_enfant_vit_france.contenu}
            </CallOut>
          )}
        </>
      )}
      {rFDemande === 'non' && (
        <>
          <EnfantInfoNoms lang={lang} formFieldsMapping={formFieldsMapping} />
          <EnfantActeNaissance
            fieldArrayMapping={fieldArrayMapping}
            filesMapping={filesMapping}
            isNew={enfant?.uuid ? 'oui' : 'non'}
            formFieldsMapping={formFieldsMapping}
            control={control}
            lang={lang}
          />
        </>
      )}
    </FormModalLayout>
  );
};

export default Enfant;
